<template>
  <div>
    <div class="va-row">
      <div class="flex md12 xs12">
        <vuestic-data-table
          class="text-center"
          :apiMode="true"
          :apiUrl="apiUrl"
          :httpOptions="httpOptions"
          :itemsPerPage="itemsPerPage"
          :defaultPerPage="defaultTablePerPage"
          :queryParams="queryParams"
          :tableFields="translatedTableFields"
          :perPageSelectorShown="false"
          :actions="true"
          :additionalActions="true"
          :filterInputLabel="$t('billing.rates.filters.service-name')"
        >
          <template slot="additionalTableControl">
            <div class="d-flex flex-column align-items-start field">
              <p class="m-0">
                {{ $t('billing.rates.filters.service-type') }}
              </p>
              <Select
                v-model="service"
                :options="services"
                :allowEmpty="false"
              />
            </div>
          </template>
        </vuestic-data-table>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import http from '../../axios'
import momentTimezone from 'moment-timezone'
import Select from '../../elements/Select.vue'

export default {
  components: {
    Select
  },
  data() {
    return {
      httpOptions: {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token'),
          'ngrok-skip-browser-warning': true,
          organization: localStorage.getItem('organizationId'),
          'Accept-Language': localStorage.getItem('locale'),
          timezone: momentTimezone.tz.guess()
        }
      },
      service: {
        id: 1,
        ident_code: 'calls'
      },
      services: [],
      tableFields: [
        {
          name: '__sequence',
          dataClass: 'text-light'
        },
        {
          name: 'sourceCode',
          title: 'billing.rates.table.source-code'
        },
        {
          name: 'destinationName',
          title: 'billing.rates.table.destination-name'
        },
        {
          name: 'destinationCode',
          title: 'billing.rates.table.destination-code'
        },
        {
          name: 'rate',
          title: 'billing.rates.table.rate'
        },
        {
          name: 'currency',
          title: 'billing.rates.table.currency'
        }
      ],
      queryParams: {
        sort: 'sort',
        page: 'page',
        perPage: 'per_page'
      },
      itemsPerPage: [
        {
          value: 10
        },
        {
          value: 25
        },
        {
          value: 50
        }
      ],
      defaultTablePerPage: 10
    }
  },
  computed: {
    ...mapGetters(['tenantID', 'currentJera', 'currentTenant']),
    apiUrl() {
      return `${process.env.VUE_APP_NEW_API}jera/rates?serviceId=${this.serviceID}`
    },
    serviceID() {
      return this.service.id ? this.service.id : 1
    },
    translatedTableFields() {
      return this.tableFields.map(field => {
        return {
          ...field,
          title: this.$t(field.title)
        }
      })
    }
  },
  methods: {
    async getServices() {
      const res = await http.get(`${process.env.VUE_APP_NEW_API}jera/services`)
      this.services = res.data
      this.service = res.data[0]
    }
  },
  watch: {
    currentTenant() {
      const organizationId = localStorage.getItem('organizationId')
      this.httpOptions.headers.organization = organizationId
    }
  },
  mounted() {
    this.getServices()
  }
}
</script>
<style lang="scss" scoped>
.vuestic-simple-select {
  min-width: 18rem;
}
</style>
